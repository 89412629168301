import React, { Component } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Highlight from 'react-syntax-highlight';
require('highlight.js/styles/default.css');
require('highlight.js/styles/atom-one-light.css');

class Snippets extends Component {
  constructor(props) {
      super(props);
      this.state = {
        snippets: []
      }
  }

  componentDidMount() {
    let snippetsUrl = 'https://vivo.digital/wp-json/wp/v2/snippets?per_page=100&order=asc&orderby=title';
    fetch(snippetsUrl)
    .then(response => response.json())
    .then(response => {
      this.setState({
        snippets: response
      })
    })
  }

  renderSnippet = snippet => {
    return (
      <ExpansionPanel key={snippet.id}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography component={'h2'} className='snippet__title'>{snippet.title.rendered}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography component={'span'}>
            <Highlight lang={snippet.snippet_language ? snippet.snippet_language : 'javascript'} value={snippet.content.rendered.replace(/(<([^>]+)>)/ig,"")} />
            <CopyToClipboard text={snippet.content.rendered.replace(/(<([^>]+)>)/ig,"")}>
              <span className="icon__copy"><FileCopyIcon/></span>
            </CopyToClipboard>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  render() {
    const { search } = this.props;
    const filteredSnippets = this.state.snippets.filter(snippet => {
      return snippet.title.rendered.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });

    return (
      <div>
        {filteredSnippets.map(snippet => {
          return this.renderSnippet(snippet);
        })}
      </div>
    );
  }
}

export default Snippets;
