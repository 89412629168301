import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import './App.css';
import Header from './Header';
import Snippets from './Snippets';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: ''
    };

    this.handler = this.handler.bind(this)
    this.search = this.search.bind(this)
  }

  handler(e) {
    this.setState({
      search: e.target.value
    })
  }

  search(e) {
  }

  render() {
    const theme = createMuiTheme({
      palette: {
        primary: {
          500: '#0a1b2a',
          contrastText: '#ba9e76',
        }
      },
      typography: {
        useNextVariants: true,
      },
    });

    return (
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <Header handler = {this.handler}  />
          <Snippets search = {this.state.search} />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;
